/**管理用户信息状态 */
import { post, get } from '@/libs/api.js'
const state = {
    info: {},
    attestation: {},
    config: {},
    isLogin: false,
}

/**store内部操作state */
const mutations = {
    setUserInfo(state, val) {//设置用户信息
        if (val) {
            val.credence = parseFloat(val.credence);
        }

        state.info = val;

        //用户登录状态
        if (state.info.uk) {
            state.isLogin = true;
        } else {
            state.isLogin = false;
        }
    },

    setAttestation(state, val) {//设置认证信息
        if (!val) return;
        state.attestation = val;
    },

    setConfig(state, val) {//其他信息
        if (!val) return;
        state.config = val;
    }
}

/**
 * 外部操作state
 */
const actions = {
    setUserInfo: ({ commit }, val) => {//外部调用后 - 设置用户信息
        commit('setUserInfo', val)
    },

    setAttestation({ commit }, val) {//外部调用后 - 设置认证信息
        if (!val) return;
        commit('setAttestation', val)
    },

    ajaxUserInfo({ commit }) {//获取个人资料公共方法
        commit('setUserInfo', {});
        commit('setConfig', {});
        commit('setAttestation', {});

        post('/user/index/information', {}, data => {
            if (data.code == 200) {
                commit('setUserInfo', data.data.info);
                commit('setConfig', data.data.config);
                commit('setAttestation', data.data.attestation);
            } else {
                sessionStorage.clear();//清空缓存，清除token登录信息
                return;
            }
        }, () => { }, err => {
            sessionStorage.clear();//清空缓存，清除token登录信息
            return;
        })
    },

    loginOut({ commit }) {
        sessionStorage.clear();//清空缓存，清除token登录信息
        commit('setUserInfo', {});
        commit('setConfig', {});
        commit('setAttestation', {});
    }
}

const getters = {}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}