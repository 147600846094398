import Vue from 'vue'
import Router from 'vue-router'
import user from './module/user.js'
Vue.use(Router)

//获取原型对象上的push函数
const originalPush = Router.prototype.push;
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
}

let router = new Router({
    mode: 'history',
    routes: [
        user,
        {
            path: '/',
            name: 'index',//首页
            component: () => import('@/views/index/index.vue'),
            meta: { title: '首页' }
        },
        {
            path: '/login/index',//登录注册
            name: 'login',
            component: () => import('@/views/login/login.vue')
        },
        {
            path: '/bind/index',//账号绑定
            name: 'bind',
            component: () => import('@/views/login/bind.vue')
        },
        {
            path: '/loginsuccess',//登录成功
            name: 'loginsuccess',
            component: () => import('@/views/login/loginsuccess.vue'),
            meta: { isLogin: true }
        },
        {
            path: '/about/index',//关于我们
            name: 'about',
            component: () => import('@/views/about/about.vue'),
            meta: { title: '关于我们', BreadCrumbs: ['关于我们'], url: ['/about/index'] }
        },
        {
            path: '/contact/index',//联系我们
            name: 'contact',
            component: () => import('@/views/about/contact.vue'),
            meta: { title: '联系我们', BreadCrumbs: ['联系我们'] }
        },
        {
            path: '/protocol/index/:id',//协议与条款
            name: 'protocol',
            component: () => import('@/views/protocol/index.vue'),
            meta: { title: '协议规则', BreadCrumbs: ['协议规则'] }
        },
        {
            path: '/help/index/:id',//帮助中心
            name: 'helps',
            component: () => import('@/views/help/index.vue'),
            meta: { title: '帮助中心', BreadCrumbs: ['帮助中心'] }
        },

        //极客雇主个人主页
        {
            path: '/firm',//工程极客详情
            name: 'firm',
            component: () => import('@/views/firm/index.vue'),
            children: [
                {
                    path: '/firm/index',//个人简介
                    name: 'firmIndex',
                    meta: {
                        title: '极客简介',
                        BreadCrumbs: ['用户列表', '极客简介'],
                        url: ['/geek/list']
                    },
                    component: () => import('@/views/firm/summary/index.vue')
                },
                {
                    path: '/firm/score',//业绩展示
                    name: 'firmScore',
                    meta: {
                        title: '业绩展示',
                        BreadCrumbs: ['用户列表', '业绩展示'],
                        url: ['/geek/list']
                    },
                    component: () => import('@/views/firm/score/index.vue')
                },
                {
                    path: '/firm/evaluate',//客户评价
                    name: 'firmEvaluate',
                    meta: {
                        title: '客户评价',
                        BreadCrumbs: ['用户列表', '客户评价'],
                        url: ['/geek/list']
                    },
                    component: () => import('@/views/firm/evaluate/index.vue')
                },
                {
                    path: '/firm/honor',//资质证书
                    name: 'firmHonor',
                    meta: {
                        title: '资质证书',
                        BreadCrumbs: ['用户列表', '资质证书'],
                        url: ['/geek/list']
                    },
                    component: () => import('@/views/firm/honor/index.vue')
                },
                {
                    path: '/firm/team',//团队人员
                    name: 'firmTeam',
                    meta: {
                        title: '团队人员',
                        BreadCrumbs: ['用户列表', '团队人员'],
                        url: ['/geek/list']
                    },
                    component: () => import('@/views/firm/team/index.vue')
                },
                {
                    path: '/firm/teamdetail',//团队人员详情
                    name: 'firmTeamDetail',
                    component: () => import('@/views/firm/team/detail.vue')
                },
                {
                    path: '/firm/article',//他的文章
                    name: 'firmArticle',
                    meta: {
                        title: '他的文章',
                        BreadCrumbs: ['用户列表', '他的文章'],
                        url: ['/geek/list']
                    },
                    component: () => import('@/views/firm/article/index.vue')
                },
                {
                    path: '/firm/employer',//雇主主页
                    name: 'firmEmployerIndex',
                    meta: {
                        title: '雇主简介',
                        BreadCrumbs: ['项目详情', '雇主简介'],
                        url: ['/task/detail']
                    },
                    component: () => import('@/views/firm/employer_summary/index.vue')
                },
                {
                    path: '/firm/task',//雇主项目主页
                    name: 'firmTaskIndex',
                    meta: {
                        title: '雇主的项目',
                        BreadCrumbs: ['项目详情', '雇主的项目'],
                        url: ['/task/detail']
                    },
                    component: () => import('@/views/firm/task/index.vue')
                },
                {
                    path: '/firm/article2',//雇主的文章
                    name: 'firmEarticle',
                    meta: {
                        title: '雇主的文章',
                        BreadCrumbs: ['项目详情', '雇主的文章'],
                        url: ['/task/detail']
                    },
                    component: () => import('@/views/firm/article/index2.vue')
                }
            ]
        },
        {
            path: '/article/index',//资源分享
            name: 'article',
            component: () => import('@/views/article/index.vue'),
            meta: { title: '资源分享', BreadCrumbs: ['资源分享'] }
        },
        {
            path: '/article/detail/:id',//资源分享-详情
            name: 'articleDetail',
            component: () => import('@/views/article/detail.vue'),
            meta: { title: '资源分享列表', BreadCrumbs: ['资源分享列表', '内容详情'], url: ['/article/index', ''] }
        },
        {
            path: '/geek/list',//极客列表
            name: 'geekList',
            component: () => import('@/views/geek/list.vue'),
            meta: { title: '用户列表', BreadCrumbs: ['用户列表'] }
        },

        //项目相关路由
        {
            path: '/task/list',//项目 - 列表
            name: 'taskList',
            component: () => import('@/views/task/list.vue'),
            meta: { title: '项目列表', BreadCrumbs: ['项目列表'] }
        },
        {
            path: '/task/detail',//项目 - 详情
            name: 'taskDetail',
            component: () => import('@/views/task/detail.vue'),
            meta: {
                title: '项目详情',
                BreadCrumbs: ['项目列表', '项目详情'],
                url: ['/task/list']
            }
        },
        {
            path: '/task/publish',//项目 - 发布
            name: 'taskPublish',
            component: () => import('@/views/task/publish.vue'),
            meta: {
                isLogin: true,
                title: '发布项目',
                BreadCrumbs: ['发布项目']
            }
        },
        {
            path: '/task/enroll',//项目 - 报名
            name: 'taskEnroll',
            component: () => import('@/views/task/enroll.vue'),
            meta: {
                isLogin: true,
                title: '项目报名',
                BreadCrumbs: ['项目列表', '项目详情', '项目报名'],
                url: ['/task/list', '/task/detail']
            }
        },
        {
            path: '/task/enroll_successtips',//项目 - 报名
            name: 'taskEnrollSuccesstips',
            component: () => import('@/views/task/enroll-successtips.vue'),
            meta: {
                isLogin: true,
                title: '项目报名',
                BreadCrumbs: ['项目列表', '项目详情', '项目报名'],
                url: ['/task/list', '/task/detail']
            }
        }
    ]
})

router.beforeEach((to, from, next) => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;//页面默认滚动到顶部
    //未登录用户访问需要登录的界面
    if (to.meta.isLogin && !sessionStorage.getItem('token')) {
        router.push('/login/index');
        next(false);
    }
    else {
        next();
    }
});

export default router;