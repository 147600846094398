import store from '@/store/'
import { websiteConfigs } from '@/libs/websiteConfigs'

const util = {
    websiteConfigs,
    /**A
     * 将url中的html字符转义， 仅转义  ', ", <, > 四个字符
     * @param  { String } str 需要转义的字符串
     * @param  { RegExp } reg 自定义的正则
     * @return { String }     转义后的字符串
     */
    unhtmlForUrl: function (str, reg) {
        return str ? str.replace(reg || /[<">'&]/g, function (a) {
            return {
                '<': '&lt;',
                '&': '&amp;',
                '"': '&quot;',
                '>': '&gt;',
                "'": '&#39;',
            }
        }) : '';
    },
    /**
     * 将str中的转义字符还原成html字符
     * @see UE.utils.unhtml(String);
     * @method html
     * @param { String } str 需要逆转义的字符串
     * @return { String } 逆转义后的字符串
     * @example
     * ```javascript
     *
     * var str = '&lt;body&gt;&amp;&lt;/body&gt;';
     *
     * //output: <body>&</body>
     * console.log( util.html( str ) );
     *
     * ```
     */
    html: function (str) {
        return str ? str.replace(/&((g|l|quo)t|amp|#39|nbsp);/g, function (m) {
            return {
                '&lt;': '<',
                '&amp;': '&',
                '&quot;': '"',
                '&gt;': '>',
                '&#39;': "'",
                '&nbsp;': ' '
            }[m]
        }) : '';
    },
    /**
     * 验证是否为空
     * @param {*} 字符串 
     */
    isEmpty(obj) {
        if (typeof obj == "undefined" || obj == null || obj == "") {
            return true;
        } else {
            return false;
        }
    },
    reHtml(html) {
        if (this.isEmpty(html)) {
            return "";
        }
        return html.replace(/src=[\'\"][\.]/g, "src=\"" + websiteConfigs.sourceUrl);
    },
    toSku_spec(object) {
        let skuspec = "";
        for (let item in object) {
            if (item != 'sku_id' && item != "sku_info") {
                skuspec += item + ":" + object[item] + "&nbsp;";
            }
        }
        return skuspec;
    },
    stringToArr(str, splitor) {
        return str.split(splitor);
    },
    reImg(url) {
        if (this.isEmpty(url)) {
            return "";
        }
        else if (!/(http|https):\/\/([\w.]+\/?)\S*/.test(url)) {
            return websiteConfigs.sourceUrl + url;
        } else {
            return url;
        }
    },
    setLS(key, value) {
        var curTime = new Date().getTime();
        localStorage.setItem(key, JSON.stringify({ data: value, time: curTime }));
    },
    getLS(key, day) {
        var data = localStorage.getItem(key);
        if (this.isEmpty(data)) {
            return null;
        }
        var dataObj = JSON.parse(data);
        if (new Date().getTime() - dataObj.time > (day * 24 * 60 * 60 * 1000)) {
            localStorage.removeItem(key);
            return null;
        } else {
            var curTime = new Date().getTime();
            localStorage.setItem(key, JSON.stringify({ data: dataObj.data, time: curTime }));
            return dataObj.data;
        }

    },
    pp(_data) {
        let _d = JSON.stringify(_data);
        return JSON.parse(_d);
    },
    /* 分隔字符串中的字段  */
    getQuery(str) {
        let query = {};
        if (str) {
            str = str.slice(1, str.length);
            let q = str.split('&');
            q.forEach(item => {
                let s = item.split('=');
                query[s[0]] = s[1] || '';
            });
        }
        return query;
    },
    formatdata(date, fmt) {
        if (date.toString().length == 10) {
            date = new Date(parseInt(date) * 1000);
        } else {
            date = new Date(parseInt(date));
        }
        if (/(y+)/.test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
        }
        let o = {
            'M+': date.getMonth() + 1,
            'd+': date.getDate(),
            'h+': date.getHours(),
            'm+': date.getMinutes(),
            's+': date.getSeconds()
        };
        for (let k in o) {
            if (new RegExp(`(${k})`).test(fmt)) {
                let str = o[k] + '';
                fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str));
            }
        }
        return fmt;
    },

    formatSeconds(value, t) {
        var secondTime = parseInt(value);// 秒
        var minuteTime = 0;// 分
        var hourTime = 0;// 小时
        var dayTime = 0;
        if (secondTime > 60) {//如果秒数大于60，将秒数转换成整数
            //获取分钟，除以60取整数，得到整数分钟
            minuteTime = parseInt(secondTime / 60);
            //获取秒数，秒数取佘，得到整数秒数
            secondTime = parseInt(secondTime % 60);
            //如果分钟大于60，将分钟转换成小时
            if (minuteTime > 60) {
                //获取小时，获取分钟除以60，得到整数小时
                hourTime = parseInt(minuteTime / 60);
                //获取小时后取佘的分，获取分钟除以60取佘的分
                minuteTime = parseInt(minuteTime % 60);
            }
            if (hourTime > 60) {
                dayTime = parseInt(hourTime / 24);
                hourTime = parseInt(hourTime % 24);
            }
        }
        var result = '';
        if (t === undefined) {
            result = "" + parseInt(secondTime) + "秒";
            result = "" + parseInt(minuteTime) + "分" + result;
            result = "" + parseInt(hourTime) + "小时" + result;
            result = "" + parseInt(dayTime) + "天" + result;
        }

        switch (t) {
            case 'dd': result = parseInt(dayTime); break;
            case 'hh': result = parseInt(hourTime); break;
            case 'mm': result = parseInt(minuteTime); break;
            case 'ss': result = parseInt(secondTime); break;
            default: result = "" + parseInt(secondTime) + "秒";
                result = "" + parseInt(minuteTime) + "分" + result;
                result = "" + parseInt(hourTime) + "小时" + result;
                result = "" + parseInt(dayTime) + "天" + result;
                break;
        }

        return result;
    }

}
export default util;