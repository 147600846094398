const validRulers = {
  email: [ // 邮箱验证
    { required: true, message: '邮箱不能为空', trigger: 'blur' },
    { type: 'email', message: '请输入正确的邮箱地址', trigger: 'blur'}
  ],
  citySelVal: [ // 城市选择验证
    { required: true, message: '请选择地区', trigger:  ['change','blur'] },
  ],
  userName:[ // 用户名
    { min: 3, max: 5, message: '长度在 3 到 50 个字符', trigger: 'blur' },
    { required: true, message: '用户名不能为空', trigger: 'blur' },
  ],
  mobile:[ // 手机号码验证
    { required: true, message: '手机号码不能为空', trigger: ['change','blur'] },
    { pattern: /^1(3|4|5|6|7|8|9)\d{9}$/, message: '手机号码不正确', trigger:  ['change','blur'] }
  ]

}
export default {
  validRulers
}







