import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App'

import { websiteConfigs } from '@/libs/websiteConfigs'
import store from '@/store/'
import router from './router'
import { post, get } from '@/libs/api' /* ajax 请求 */
import validata from '@/libs/validata'
import * as custom from '@/libs/filter' /*  vue模板渲染filter */
import util from '@/libs/util' /* 常用类库 */
import * as directives from '@/libs/directives' /* 自定义指令  */
import validRulers from '@/libs/validator/ruler' // 验证规则 
import Clipboard from 'clipboard' // 复制链接
import viewImage from '@/components/view-images/view-images.vue' //图片弹出
import MetaInfo from 'vue-meta-info'//meta信息
import assets from '@/libs/assets'


Vue.prototype.Clipboard = Clipboard;
Vue.config.productionTip = false;

Object.keys(custom).forEach(key => {
  Vue.filter(key, custom[key]);
})

/* 自定义指令  */
Object.keys(directives).forEach(key => {
  Vue.directive(key, directives[key]);
})

Vue.prototype.$img = assets;
Vue.prototype.util = util;
Vue.prototype.websiteConfigs = websiteConfigs; // 全局站点配置
Vue.prototype.validRulers = validRulers.validRulers; // 验证规则
Vue.prototype.validata = validata;
Vue.prototype.post = post;
Vue.prototype.get = get;

Vue.use(ElementUI);
Vue.component('viewImage', viewImage);
Vue.use(MetaInfo);

if(/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
  window.location.href = "https://wap.xunlongwang.com/";
}

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
