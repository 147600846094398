<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "App",
    created() {
        this.ajaxUserInfo();//获取用户信息
    },
    methods: {
        ...mapActions("userInfo", ["ajaxUserInfo"]),//刷新用户数据方法
    }
};
</script>

<style lang="less">
@import "./styles/common.less";
@import "./styles/ucCommon.less";
@import "./assets/iconfont/iconfont.css";
</style>
