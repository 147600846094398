import Vue from 'vue';
import Vuex from 'vuex';
import userInfo from './modules/userInfo'
import createPersistedState from "vuex-persistedstate"
import websitInfo from './modules/websitInfo'
Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        userInfo,
        websitInfo
    },
    plugins: [createPersistedState()]
});
