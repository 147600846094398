/**网站全局配置*/
import { post, get } from '@/libs/api.js'
const state = {
    webkitData: '',
    timestamp: '',
    links: ''
}

const mutations = {
    updataWebSit(state, val) {
        state.webkitData = val;
    },
    updataTimestamp(state, val) {
        state.timestamp = val;
    },
    setLinks(state, val) {
        state.links = val;
    }
}

const actions = {
    acUpdataTimestamp: ({ commit }, val) => {
        commit('updataTimestamp', val)
    },

    acUpdataWebSit({ commit, val }) {//获取网站信息
        post("/system/Base/info", { id: 12 }, res => {
            if (res.code == 200) {
                commit('updataWebSit', res.data);
            }
        });
    },

    updateLines({ commit, val }) {
        post("/article/index/lines", {}, function (res) {
            if (res.code == 200) {
                commit('setLinks', res.data);
            }
        });
    }
}

const getters = {
    getWebsiteDatas(state) {
        return state.webkitData
    },
    getTimestamp(state) {
        return state.timestamp
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}