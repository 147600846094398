const user = {
    path: 'user',
    name: 'usermain',//首页
    component: () => import('@/views/user/main.vue'),
    children:
        [
            //个人信息
            {
                path: '/user',
                redirect: '/user/index',
            },
            {
                path: '/user/index',//首页
                name: 'userIndex',
                component: () => import('@/views/user/index.vue'),
                meta: { isLogin: true, title: '首页', BreadCrumbs: ['用户中心'], url: ['/user/index'] }
            },
            {
                path: '/user/info', //个人信息
                name: 'userInfo',
                component: () => import('@/views/user/info/index.vue'),
                meta: { isLogin: true, title: '个人信息', BreadCrumbs: ['用户中心', '个人信息'], url: ['/user/index'] }
            },
            {
                path: '/user/attest', //认证
                name: 'userAttest',
                component: () => import('@/views/user/attest/index.vue'),
                meta: { isLogin: true, title: '认证', BreadCrumbs: ['用户中心', '认证'], url: ['/user/index'] }
            },
            {
                path: '/user/attest/edit', //认证 - 编辑
                name: 'userAttestEdit',
                component: () => import('@/views/user/attest/edit.vue'),
                meta: { isLogin: true, title: '认证', BreadCrumbs: ['用户中心', '认证'], url: ['/user/index'] }
            },
            {
                path: '/user/attest/success', //认证 - 编辑
                name: 'userAttestSuccess',
                component: () => import('@/views/user/attest/success.vue'),
                meta: { isLogin: true, title: '认证', BreadCrumbs: ['用户中心', '认证'], url: ['/user/index'] }
            },

            //个人中心 - 文章
            {
                path: '/user/article/:id', //文章 - 列表
                name: 'userArticle',
                component: () => import('@/views/user/article/index.vue'),
                meta: { isLogin: true, title: '资源分享', BreadCrumbs: ['用户中心', '资源分享'], url: ['/user/index'] }
            },
            {
                path: '/user/articleEdit/:id', //文章 - 发布/编辑
                name: 'userArticleEdit',
                component: () => import('@/views/user/article/edit.vue'),
                meta: { isLogin: true, title: '文章发布', BreadCrumbs: ['用户中心', '发布资源'], url: ['/user/index'] }
            },
            {
                path: '/user/articleView/:id', //文章 - 查看
                name: 'userArticleView',
                component: () => import('@/views/user/article/views.vue'),
                meta: { isLogin: true, title: '查看文章', BreadCrumbs: ['用户中心', '查看文章'], url: ['/user/index'] }
            },

            //消息中心
            {
                path: '/user/message', //消息 - 列表
                name: 'userMsg',
                component: () => import('@/views/user/message/index.vue'),
                meta: { isLogin: true, title: '消息中心', BreadCrumbs: ['用户中心', '消息中心'], url: ['/user/index'] }
            },
            {
                path: '/user/message/detail',//消息 - 详情
                name: 'userMsgDetail',
                component: () => import('@/views/user/message/detail.vue'),
                meta: { isLogin: true, title: '消息详情', BreadCrumbs: ['用户中心', '消息中心', '消息详情'], url: ['/user/index'] }
            },
            {
                path: '/user/message/reply',//消息 - 回复
                name: 'userMagReply',
                component: () => import('@/views/user/message/reply.vue'),
                meta: { isLogin: true, title: '回复消息', BreadCrumbs: ['用户中心', '消息中心', '回复消息'], url: ['/user/index'] }
            },
            {
                path: '/user/message/sendmsg', // 消息 - 发送
                name: 'userMagSend',
                component: () => import('@/views/user/message/sendmsg.vue'),
                meta: { isLogin: true, title: '发送消息', BreadCrumbs: ['用户中心', '消息中心', '发送消息'], url: ['/user/index'] }
            },
            {
                path: '/user/message/sendsuccess', //消息 - 发送成功提示
                name: 'userMagSucc',
                component: () => import('@/views/user/message/sendsuccess.vue'),
                meta: { isLogin: true, title: '发送成功', BreadCrumbs: ['用户中心', '消息中心', '发送成功'], url: ['/user/index'] }
            },

            //老待办事项
            {
                path: '/user/todos/',//老待办事项 - 列表
                name: 'userTodos',
                component: () => import('@/views/user/todos/todosList.vue'),
                children: [
                    {
                        path: '/user/todos/',//老待办事项 - 全部
                        redirect: '/user/todos/list/all'
                    },
                    {
                        path: '/user/todos/list',//老待办事项 - 列表
                        redirect: '/user/todos/list/all'
                    },
                    {
                        path: '/user/todos/list/:id', //老待办事项 - 各种状态集
                        name: 'userTodosList',
                        component: () => import('@/views/user/todos/todosStatus.vue'),
                        meta: { isLogin: true, title: '待办事项', BreadCrumbs: ['用户中心', '待办事项'], url: ['/user/index'] }
                    },
                ]
            },
            {
                path: '/user/todos/detail/:id', //老待办事项 - 详情
                name: 'userTodosDetail',
                component: () => import('@/views/user/todos/todosDetail.vue'),
                meta: { isLogin: true, title: '待办事项详情', BreadCrumbs: ['用户中心', '待办事项详情'], url: ['/user/index'] }
            },
            {
                path: '/user/todos/sendInvit', //待办事项 - 发送邀请
                name: 'EmsendInvit',
                component: () => import('@/views/user/todos/sendInvit.vue'),
                meta: { isLogin: true, user_type: 2 }
            },
            {
                path: '/user/collect',//关注与收藏
                name: 'collect',
                component: () => import('@/views/user/collect/index.vue'),
                meta: { isLogin: true, title: '关注与收藏', BreadCrumbs: ['用户中心', '关注与收藏'], url: ['/user/index'] }
            },
            {
                path: '/user/appraise',//评价管理
                name: 'evaluateMang',
                component: () => import('@/views/user/appraise/list.vue'),
                meta: { isLogin: true, title: '评价管理', BreadCrumbs: ['用户中心', '评价管理'], url: ['/user/index'] }
            },

            //我的钱包
            {
                path: '/user/wallet/',//我的钱包
                redirect: '/user/wallet/list',
                name: 'userWallet',
                component: () => import('@/views/user/wallet/walletList.vue'),
                children: [
                    {
                        path: '/user/wallet/list', //我的钱包 - 列表
                        name: 'userWalletList',
                        component: () => import('@/views/user/wallet/myWallet.vue'),
                        meta: { isLogin: true, title: '我的钱包', BreadCrumbs: ['用户中心', '我的钱包'], url: ['/user/index'] }
                    },
                    {
                        path: '/user/wallet/commission', //我的钱包 - 佣金
                        name: 'userWalletCommission',
                        component: () => import('@/views/user/wallet/mycommission.vue'),
                        meta: { isLogin: true, title: '我的佣金', BreadCrumbs: ['用户中心', '我的钱包', '我的佣金'], url: ['/user/index', '/user/wallet/list'] }
                    },
                ]
            },
            {
                path: '/user/wallet/add_bankcard',//我的钱包-绑定银行卡-储蓄
                name: 'addBankCard',
                component: () => import('@/views/user/wallet/addBankCard.vue'),
                meta: { isLogin: true, title: '添加银行卡', BreadCrumbs: ['用户中心', '我的钱包', '添加银行卡'], url: ['/user/index', '/user/wallet/list'] }
            },
            {
                path: '/user/wallet/my_bankcard',//我的钱包 - 我的银行卡信息
                name: 'myBankCard',
                component: () => import('@/views/user/wallet/myBankCard.vue'),
                meta: { isLogin: true, title: '我的银行卡信息', BreadCrumbs: ['用户中心', '我的银行卡信息'], url: ['/user/index'] }
            },
            {
                path: '/user/wallet/succ',//我的钱包-绑定银行卡成功
                name: 'userWalletSucc',
                component: () => import('@/views/user/wallet/addsuccess.vue'),
                meta: { isLogin: true, title: '成功绑定银行卡', BreadCrumbs: ['用户中心', '成功绑定银行卡'], url: ['/user/index'] }
            },

            //安全中心
            {
                path: '/user/setting',//账户安全-账户安全
                name: 'userSetting',
                component: () => import('@/views/user/setting/accountSafe.vue'),
                meta: { isLogin: true, title: '设置', BreadCrumbs: ['用户中心', '设置'], url: ['/user/index'] }
            },
            {
                path: '/user/validpassword',//账户安全-验证密码
                name: 'validpassword',
                component: () => import('@/views/user/setting/validPassword.vue'),
                meta: { isLogin: true, title: '设置', BreadCrumbs: ['用户中心', '验证密码'], url: ['/user/index'] }
            },
            {
                path: '/user/setting/password',//账户安全-修改密码 ,账户安全-支付密码
                name: 'userSettingPassword',
                component: () => import('@/views/user/setting/modifyPassword.vue'),
                meta: { isLogin: true, title: '修改密码', BreadCrumbs: ['用户中心', '修改密码'], url: ['/user/index'] }
            },
            {
                path: '/user/setting/findPaymentPassword',//账户安全-找回支付密码
                name: 'findPaymentPassword',
                component: () => import('@/views/user/setting/findPaymentPassword.vue'),
                meta: { isLogin: true, title: '找回支付密码', BreadCrumbs: ['用户中心', '设置支付密码'], url: ['/user/index'] }
            },
            {
                path: '/user/setting/mobile',//账户安全-绑定手机
                name: 'userSettingMobile',
                component: () => import('@/views/user/setting/bindMphone.vue'),
                meta: { isLogin: true, title: '绑定手机', BreadCrumbs: ['用户中心', '绑定手机'], url: ['/user/index'] }
            },
            {
                path: '/user/setting/email',//账户安全-绑定邮箱
                name: 'userSettingE-mail',
                component: () => import('@/views/user/setting/bindMailbox.vue'),
                meta: { isLogin: true, title: '绑定邮箱', BreadCrumbs: ['用户中心', '绑定邮箱'], url: ['/user/index'] }
            },
            {
                path: '/user/setting/valid_mail',//账户安全-验证邮箱
                name: 'userSettingPassword',
                component: () => import('@/views/user/setting/validMailbox.vue'),
                meta: { isLogin: true, title: '验证邮箱', BreadCrumbs: ['用户中心', '验证邮箱'], url: ['/user/index'] }
            },
            {
                path: '/user/setting/modify-mobile',//账户安全-修改绑定手机
                name: 'userSettingModify-mobile',
                component: () => import('@/views/user/setting/modifyPhone.vue'),
                meta: { isLogin: true, title: '修改手机号', BreadCrumbs: ['用户中心', '修改手机号'], url: ['/user/index'] }
            },
            {
                path: '/user/setting/modify-email',//账户安全-修改绑定邮箱
                name: 'userSettingModify-email',
                component: () => import('@/views/user/setting/modifyMailbox.vue'),
                meta: { isLogin: true, title: '修改邮箱', BreadCrumbs: ['用户中心', '修改邮箱'], url: ['/user/index'] }
            },
            {
                path: '/user/setting/password/succ',//账户安全 - 修改密码成功
                name: 'userSettingPasswordSucc',
                component: () => import('@/views/user/setting/successPassword.vue'),
                meta: { isLogin: true, title: '修改密码成功', BreadCrumbs: ['用户中心', '修改密码成功'], url: ['/user/index'] }
            },
            // {
            //     path: '/user/setting/privacy',//隐私设置
            //     name: 'privacy',
            //     component: () => import('@/views/user/setting/privacy.vue'),
            //     meta: { isLogin: true, title: '隐私设置', BreadCrumbs: ['用户中心', '隐私设置'], url: ['/user/index'] }
            // },

            //用户 - 项目中心
            {
                path: '/user/task/list',//项目列表
                name: 'userTask',
                component: () => import('@/views/user/task/task-list.vue'),
                meta: { isLogin: true, title: '项目列表', BreadCrumbs: ['用户中心', '我的项目'], url: ['/user/index', '/user/task/list'] }
            },
            {
                path: '/user/task/todo',//待办事项
                name: 'userTask',
                component: () => import('@/views/user/task/task-todo.vue'),
                meta: { isLogin: true },
                children: [
                    {
                        path: '/user/task/geek', //极客 - 待办
                        name: 'userTaskGeek',
                        component: () => import('@/views/user/task/todo-geek.vue'),
                        meta: { isLogin: true, title: '待办事项', BreadCrumbs: ['用户中心', '待办事项'], url: ['/user/index'] }
                    },
                    {
                        path: '/user/task/employer', //极客 - 待办
                        name: 'userTaskGeek',
                        component: () => import('@/views/user/task/todo-employer.vue'),
                        meta: { isLogin: true, title: '待办事项', BreadCrumbs: ['用户中心', '待办事项'], url: ['/user/index'] }
                    }
                ]
            }
            ,
            {
                path: '/user/task/invite',//项目邀请
                name: 'userTask',
                component: () => import('@/views/user/task/task-invite.vue'),
                meta: { isLogin: true }
            },
            {
                path: '/user/task/appraise',//项目评价
                name: 'userTaskData',
                component: () => import('@/views/user/task/task-appraise.vue'),
                meta: { isLogin: true, title: '项目评价', BreadCrumbs: ['用户中心', '项目评价'], url: ['/user/index'] }
            },
            {
                path: '/user/task/file',//项目资料库
                name: 'userTaskData',
                component: () => import('@/views/user/task/task-file.vue'),
                meta: { isLogin: true, title: '项目资料', BreadCrumbs: ['用户中心', '项目资料'], url: ['/user/index'] }
            },
            {
                path: '/user/task/result',//项目成果
                name: 'userTaskData',
                component: () => import('@/views/user/task/task-result.vue'),
                meta: { isLogin: true, title: '项目成果', BreadCrumbs: ['用户中心', '项目成果'], url: ['/user/index'] }
            },
            {
                path: '/user/task/update_contract',//填写项目合同信息
                name: 'updateContract',
                component: () => import('@/views/user/task/task-contract-update.vue'),
                meta: { isLogin: true, title: '项目合同', BreadCrumbs: ['用户中心', '待办事项', '生成项目合同'], url: ['/user/index'] }
            }, {
                path: '/user/task/appeal',//项目调解
                name: 'userTaskAppeal',
                component: () => import('@/views/user/task/task-appeal.vue'),
                meta: { isLogin: true, title: '项目调解', BreadCrumbs: ['用户中心', '项目调解'], url: ['/user/index'] }
            },
            {
                path: '/user/task/appeal_detail',//项目调解详情
                name: 'userTaskAppealDetail',
                component: () => import('@/views/user/task/task-appeal-detail.vue'),
                meta: { isLogin: true }
            },
        ]
};

export default user;