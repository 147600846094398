import { post, get } from '@/libs/api';
import util from '@/libs/util'

export default {
    mobile: function () {
        return (rule, value, callback) => {
            if (util.isEmpty(value)) {
                callback();
            }
            else if (!value.length === 11 || !/^(1([358][0-9]|4[1579]|66|7[0135678]|9[89])[0-9]{8})$/.test(value)) {
                callback(new Error('请输入正确的手机号'))
            } else {
                callback();
            }
        }
    },
    phone: function () {
        return (rule, value, callback) => {
            if (util.isEmpty(value)) {
                callback();
            } else if (!/^(1([358][0-9]|4[1579]|66|7[0135678]|9[89])[0-9]{8})$/.test(value) && !/^\d{3}-\d{7,8}|\d{4}-\d{7,8}$/.test(value)) {
                callback(new Error('电话号码'))
            } else {
                callback();
            }
        }
    },
    equalsPWD: function (pwd) {
        return (rule, value, callback) => {
            if (value !== pwd) {
                callback(new Error('两次输入的密码不一致'))
            } else {
                callback();
            }
        }
    },
    password: function () {
        return (rule, value, callback) => {
            if (!/^.*(?=.{6,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*? ]).*$/.test(value)) {
                callback(new Error('最少6位，包括至少1个大写字母，1个小写字母，1个数字，1个特殊字符'))
            } else {
                callback();
            }
        }
    },
    checkMobile: function () {
        return (rule, value, callback) => {
            post(
                {
                    method: "api.module.member.public.check",
                    name: "mobile",
                    param: value
                },
                function (data) {
                    if (200 !== data.code) {
                        callback(new Error("该手机号已注册"))
                    } else {
                        callback();
                    }
                }
            )
        }
    },
    checkUsername: function () {
        return (rule, value, callback) => {
            post(
                {
                    method: "api.module.member.public.check",
                    name: "username",
                    param: value
                },
                function (data) {
                    if (200 !== data.code) {
                        callback(new Error("该手机号已注册"))
                    } else {
                        callback();
                    }
                }
            )
        }
    },
    idCard: function () {
        return (rule, value, callback) => {
            /*
            * 身份证15位编码规则：dddddd yymmdd xx p
            * dddddd：6位地区编码
            * yymmdd: 出生年(两位年)月日，如：910215
            * xx: 顺序编码，系统产生，无法确定
            * p: 性别，奇数为男，偶数为女
            *
            * 身份证18位编码规则：dddddd yyyymmdd xxx y
            * dddddd：6位地区编码
            * yyyymmdd: 出生年(四位年)月日，如：19910215
            * xxx：顺序编码，系统产生，无法确定，奇数为男，偶数为女
            * y: 校验码，该位数值可通过前17位计算获得
            *
            * 前17位号码加权因子为 Wi = [ 7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2 ]
            * 验证位 Y = [ 1, 0, 10, 9, 8, 7, 6, 5, 4, 3, 2 ]
            * 如果验证码恰好是10，为了保证身份证是十八位，那么第十八位将用X来代替
            * 校验位计算公式：Y_P = mod( ∑(Ai×Wi),11 )
            * i为身份证号码1...17 位; Y_P为校验码Y所在校验码数组位置
            */
            //15位和18位身份证号码的正则表达式
            var regIdCard = /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/;
            //如果通过该验证，说明身份证格式正确，但准确性还需计算
            if (regIdCard.test(value)) {
                if (value.length == 18) {
                    var idCardWi = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2); //将前17位加权因子保存在数组里
                    var idCardY = new Array(1, 0, 10, 9, 8, 7, 6, 5, 4, 3, 2); //这是除以11后，可能产生的11位余数、验证码，也保存成数组
                    var idCardWiSum = 0; //用来保存前17位各自乖以加权因子后的总和
                    for (var i = 0; i < 17; i++) {
                        idCardWiSum += value.substring(i, i + 1) * idCardWi[i];
                    }
                    var idCardMod = idCardWiSum % 11;//计算出校验码所在数组的位置
                    var idCardLast = value.substring(17);//得到最后一位身份证号码
                    //如果等于2，则说明校验码是10，身份证号码最后一位应该是X
                    if (idCardMod == 2) {
                        if (idCardLast == "X" || idCardLast == "x") {
                            callback();
                        } else {
                            callback(new Error('请输入正确的身份证号'))
                        }
                    } else {
                        //用计算出的验证码与最后一位身份证号码匹配，如果一致，说明通过，否则是无效的身份证号码
                        if (idCardLast == idCardY[idCardMod]) {
                            callback();
                        } else {
                            callback(new Error('请输入正确的身份证号'))
                        }
                    }
                }
            } else {
                callback(new Error('请输入正确的身份证号'))
            }
        }

    }

}