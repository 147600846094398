import util from './util'
let padLeftZero = (str) => {
    return ('00' + str).substr(str.length);
};
let formatdata = (date, fmt) => {
    if (date.toString().length == 10) {
        date = new Date(parseInt(date) * 1000);
    } else {
        date = new Date(parseInt(date));
    }
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    let o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds()
    };
    for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
            let str = o[k] + '';
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str));
        }
    }
    return fmt;
};
let formatDate = (date) => {
    return formatdata(date, 'yyyy-MM-dd');
};
let formatDateY = (date) => {
    return formatdata(date, 'yyyy年');
};
let formatDateMD = (date) => {
    return formatdata(date, 'MM.dd');
};
let formatDateTime = (date) => {
    return formatdata(date, 'yyyy-MM-dd hh:mm:ss');
};

const changePrice = (value) => {
    if (util.isEmpty(value)) {
        return 0;
    }
    let reaVal = parseInt(value);
    return reaVal;
}

const splicePrice = (value) => {
    if (util.isEmpty(value)) {
        return "00";
    }
    let samllnub = parseFloat(value).toFixed(2);
    let b = samllnub.toString().split(".");
    let y = b[1];
    return y;
}
const numFilter = (value) => {
    value = value || 0;
    let realVal = parseFloat(value).toFixed(2);
    return realVal;
}
const formatSeconds = (value, t) => {
    var secondTime = parseInt(value);// 秒
    var minuteTime = 0;// 分
    var hourTime = 0;// 小时
    var dayTime = 0;
    if (secondTime > 60) {//如果秒数大于60，将秒数转换成整数
        //获取分钟，除以60取整数，得到整数分钟
        minuteTime = parseInt(secondTime / 60);
        //获取秒数，秒数取佘，得到整数秒数
        secondTime = parseInt(secondTime % 60);
        //如果分钟大于60，将分钟转换成小时
        if (minuteTime > 60) {
            //获取小时，获取分钟除以60，得到整数小时
            hourTime = parseInt(minuteTime / 60);
            //获取小时后取佘的分，获取分钟除以60取佘的分
            minuteTime = parseInt(minuteTime % 60);
        }
        if (hourTime > 60) {
            dayTime = parseInt(hourTime / 24);
            hourTime = parseInt(hourTime % 24);
        }
    }
    var result = '';
    if (t === undefined) {
        result = "" + parseInt(secondTime) + "秒";
        result = "" + parseInt(minuteTime) + "分" + result;
        result = "" + parseInt(hourTime) + "小时" + result;
        result = "" + parseInt(dayTime) + "天" + result;
    }

    switch (t) {
        case 'dd': result = parseInt(dayTime); break;
        case 'hh': result = parseInt(hourTime); break;
        case 'mm': result = parseInt(minuteTime); break;
        case 'ss': result = parseInt(secondTime); break;
        default: result = "" + parseInt(secondTime) + "秒";
            result = "" + parseInt(minuteTime) + "分" + result;
            result = "" + parseInt(hourTime) + "小时" + result;
            result = "" + parseInt(dayTime) + "天" + result;
            break;
    }

    return result;
}
const jsonStringify = (o) => {
    return JSON.stringify(o);
}


const idcard = (value) => {
    if (value) {
        return value.replace(/^(.{3})(?:\d+)(.{4})$/, "$1***********$2");
    }
    return value
}
/* 根据时间戳，算出年龄，注，后台给出的时间戳和js获取的时间戳可能位数不一致，后台的时间戳位数是10位的，为秒级，js获取的为毫秒级，需要转换才能使用 */
const getAges = (timestamp) => {
    timestamp = parseInt(timestamp);
    let nowstamp = new Date().getTime();
    if (timestamp.length == 10) {
        timestamp = parseInt(timestamp * 1000);
    }
    return (new Date(nowstamp).getFullYear() - new Date(timestamp).getFullYear()) || '无';
}
/* 字符串截取，str需要截取的字符串，start 开始位置，end 结束位置 */
const cutString = (str, start, end) => {
    str += '';
    return str.substring(start, end);
}
/* 对象是否有某个属性 */
const hasProperty = (obj, property) => {
    if (obj instanceof Object) {
        return obj.hasOwnProperty(property);
    }
    return false;
}
const phoneFormatMethd = (phone, spliter) => { // 格式化手机号码，用“-”分隔
    if (!phone) {
        return;
    }
    let str = phone.toString().replace(/ /g, '');
    let len = str.length;
    switch (true) {
        case len > 11:
            str = str.substr(0, 3) + spliter + str.substr(3, 4) + spliter + str.substr(7, 4);
            break;
        case len > 7:
            str = str.substr(0, 3) + spliter + str.substr(3, 3) + spliter + str.substr(6);

            break;
        case len > 3:
            str = str.substr(0, 3) + spliter + str.substr(3);
            break;
        default:
            break;
    }


    return str;
}
/*
     * 参数说明：
     * number：要格式化的数字
     * decimals：保留几位小数
     * dec_point：小数点符号
     * thousands_sep：千分位符号
     * eg： console.log(number_format(1000.123, 2));  // 1,000.13
     * */
const numberFormat = (number, decimals, dec_point, thousands_sep) => {
    number = (number + '').replace(/[^0-9+-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 2 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.ceil(n * k) / k;
        };

    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    var re = /(-?\d+)(\d{3})/;
    while (re.test(s[0])) {
        s[0] = s[0].replace(re, "$1" + sep + "$2");
    }

    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}
/* 按符号拆分指定字符串为数组 */
const spliceString = (str, separate) => {
    return (str + '').split(separate);
}
/* js 邮箱部分用*号替换 @params { String } email 需要传入的邮箱号码 */
const encryptedmailbox = (email) => {

    if (String(email).indexOf('@') > 0) {

        var str = email.split('@');

        var _s = '';

        var new_email = "";

        if (str[0].length > 3) { //@前面多于3位

            for (var i = 3; i < str[0].length; i++) {

                _s += '*';

            }

            new_email = str[0].substr(0, 3) + _s + '@' + str[1];

        } else { //@前面小于等于于3位

            for (var i = 1; i < str[0].length; i++) {

                _s += '*'

            }

            new_email = str[0].substr(0, 1) + _s + '@' + str[1]

        }

    }

    return new_email;

}
/*  手机号码中间四位用*号替换 phone 为传入的手机号码  @params {String} phone 需要传入的手机号码*/
const encryptedmPhoneNum = (phone) => {
    if (!phone) return;
    phone = phone.toString();
    return phone.replace(/^(\d{4})\d{4}(\d+)/, "$1****$2");
}
export {
    formatdata, formatDateTime, formatDate, formatDateMD,
    formatDateY, changePrice, splicePrice, numFilter,
    formatSeconds, idcard, jsonStringify, getAges, cutString, hasProperty,
    phoneFormatMethd,/* 电话号码格式化 */
    numberFormat, // 金额格式化
    encryptedmailbox, // 邮箱部分*号替换
    encryptedmPhoneNum, // 手机号码部分*号替换
}
