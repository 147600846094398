/* 图片预览功能
在需要的页面引入该组件后
@param Array imagesList: 为图片列表资源，
@param Boolean isShowImageView: 是否显示预览图
@param Number curIndex: 当前预览锁定，从1开始

调用方法，如下面是点击图片查看
引入组件 import ImageViewer from "@/components/view-images/view-images.vue";
<img :src="imagesList[0]" @click="isShowImageView=true" />
<image-viewer
    :imagesList="imagesList"
    :isVisible="isShowImageView"
    :curIndex="curindex"
    @hidenImgs="isShowImageView = false"
></image-viewer>

 */

<template>
    <el-dialog :title="title+'  '+curSlideIndex +'/'+imagesList.length"
               :visible.sync="isHiddenDialog"
               :width="width"
               :center="true"
               @closed="closedDialog">
        <el-carousel indicator-position="none"
                     :height="height"
                     :autoplay="false"
                     :initial-index="curSlideIndex-1"
                     @change="changeSlide"
                     :loop="false">
            <el-carousel-item v-for="item in imagesList"
                              :key="item">
                <el-image :src="item"
                          fit="cover"
                          :height="height"
                          class="thisimg">
                    <div slot="placeholder"
                         class="image-slot">
                        加载中
                        <span class="dot">...</span>
                    </div>
                </el-image>
            </el-carousel-item>
        </el-carousel>
    </el-dialog>
</template>
<script>
export default {
    data() {
        return {
            action: "",
            curSlideIndex: this.curIndex,
            isHiddenDialog: this.isVisible
        };
    },
    props: {
        imagesList: {
            type: Array,
            default: []
        },
        value: {
            type: Boolean,
            default: true
        },
        height: {
            type: String,
            default: "500px"
        },
        width: {
            type: String,
            default: "1000px"
        },
        title: {
            type: String,
            default: "图片预览"
        },
        curIndex: {
            type: Number,
            default: 0
        }
    },
    created() {},
    methods: {
        changeSlide(cur) {
            // 改变
            this.curSlideIndex = cur + 1;
        },
        closedDialog() {
            this.isHiddenDialog = false;
            this.$emit("input", this.isHiddenDialog);
        }
    },
    watch: {
        value(val) {
            this.isHiddenDialog = val;
        }
    }
};
</script>
<style lang="less" scorped>
.thisimg {
    width: 100%;
    height: 100%;
}
</style>
